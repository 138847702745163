import { API, useFileManager } from "@hooks";
import {
  InputHTMLAttributes,
  LabelHTMLAttributes,
  PropsWithChildren,
  createRef,
  useEffect,
  useRef,
  useState,
} from "react";
interface Props extends PropsWithChildren {
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  labelProps?: LabelHTMLAttributes<HTMLLabelElement>;
  containerClassName?: string;
  placeholder?: string;
  fileDuration?: {
    inputProps?: InputHTMLAttributes<HTMLInputElement>;
  };
  fileCover?: {
    inputProps?: InputHTMLAttributes<HTMLInputElement>;
    onChange?: (result: { file: File; url: string }) => void;
  };
  type: string;
  setFile: any;
}
const CustomInputFile = (props: Props) => {
  const { storeFile, fileProgress, file: test } = API.useApi();
  if (test.trim() != "") {
    props.setFile(test);
  }

  const id = Math.round(Math.random() * 1000000);

  const [file, setFile] = useState<File | null>(null);
  const [duration, setDuration] = useState<string>("");
  const fileDuration = useFileManager();
  const fileCover = useFileManager();
  const cover = createRef<HTMLInputElement>();

  useEffect(() => {
    if (file) {
      if (props.fileDuration) {
        fileDuration.getDuration({ file, type: "audio" });
      }
      if (props.fileCover) {
        fileCover.generateVideoThumbnail(file).then((result) => {
          if (props.fileCover && props.fileCover.onChange) {
            props.fileCover.onChange(result);
          }
          const container = new DataTransfer();
          container.items.add(result.file);
          if (cover.current) {
            cover.current.files = container.files;
          }
        });
      }
    } else {
      setDuration("");
    }
  }, [file]);

  useEffect(() => {
    if (fileDuration.result && props.fileDuration) {
      setDuration(fileDuration.result);
    }
  }, [fileDuration.result]);

  return (
    <div className="flex flex-col">
      <div
        className={"flex flex-col w-full relative " + props.containerClassName}
      >
        <label
          htmlFor={"file-" + id}
          className="text-sm font-medium md:text-md"
          {...props.labelProps}
        />
        <div className="input h-[44px] text-sm md:text-md text-white400">
          {file ? file.name : props.placeholder}
        </div>
        {props?.fileDuration ? (
          <input
            type="hidden"
            {...props.fileDuration.inputProps}
            value={duration}
          />
        ) : null}
        {props?.fileCover ? (
          <input
            type="file"
            ref={cover}
            style={{ opacity: 0, display: "none" }}
            {...props.fileCover.inputProps}
            accept={
              props.type === "image"
                ? ".png, .jpg, .jpeg, .webp"
                : props.type === "video"
                ? ".mp4, .mpeg"
                : ".mp3"
            }
          />
        ) : null}
        <input
          id={"file-" + id}
          onChange={async ({ target: { files } }) => {
            if (files?.length) {
              const file = files[0];
              setFile(file);
            } else {
              setFile(null);
            }
          }}
          type="file"
          className="text-sm md:text-md absolute w-full h-full left-0 top-0"
          {...props.inputProps}
        />
      </div>

      <div
        className="relative text-center py-1 bg-primary text-white rounded-lg cursor-pointer overflow-hidden"
        onClick={async () => {
          // تشخیص نوع فایل بر اساس MIME type
          let fileType = "";
          if (file?.type.startsWith("image/")) {
            fileType = "image";
          } else if (file?.type.startsWith("video/")) {
            fileType = "video";
          } else if (file?.type.startsWith("audio/")) {
            fileType = "audio";
          } else {
            fileType = "unknown"; // در صورت نبودن در دسته‌های بالا
          }

          const formData = new FormData();
          formData.append("type", fileType);
          if (file != null) {
            formData.append("file", file);
          }

          await storeFile("manager/blogs/upload_media", formData);
        }}
      >
        {fileProgress != 0 ? (
          <div
            className="absolute right-0 top-0 h-full bg-white opacity-30"
            style={{ width: `${fileProgress}%`, transition: "width 0.2s" }}
          />
        ) : null}

        <span className="relative z-10">
          {fileProgress != 0 ? `در حال آپلود ${fileProgress}%` : "ارسال"}
        </span>
      </div>
    </div>
  );
};

export default CustomInputFile;
