import { Form, Input } from "@components";
import { CardHeader, FormLoading } from "@components/common";
import { API } from "@hooks";
import { Fragment, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router";
import SunEditor from "suneditor-react";

const Create = () => {
  const navigate = useNavigate();
  const { item, find, setItem, loading, setLoading, update, store, progress } =
    API.useApi();

  const { id } = useParams();
  const { search } = useLocation();

  let paginate = new URLSearchParams(search).get("paginate");
  const [file, setFile] = useState<string | null>(null);
  useEffect(() => {
    setLoading(true);
    if (id) {
      find("/manager/blogs", id);
    } else {
      setItem(null);
      setTimeout(() => {
        setLoading(false);
      }, 10);
    }
  }, [id]);
  console.log(file);
  return (
    <Fragment>
      <CardHeader
        label="لیست مقاله ها"
        textButton={"لیست مقاله ها"}
        toButton={"/blogs"}
      />
      {loading ? (
        <FormLoading />
      ) : (
        <div className="my-10 ">
          <Form
            progress={progress}
            formProps={{ className: "flex flex-col" }}
            buttonProps={{
              children: "ثبت اطلاعات",
              className:
                "self-end px-3 py-3 mt-3 text-white bg-primary rounded-lg",
            }}
            onSend={async (data: FormData) => {
              if (id) {
                data.append("_method", "PUT");
                await update("manager/blogs", data, id);

                if (paginate) {
                  navigate(`/blogs?paginate=${paginate}`);
                } else {
                  navigate(`/blogs`);
                }
              } else {
                await store("manager/blogs", data);

                if (paginate) {
                  navigate(`/blogs?paginate=${paginate}`);
                } else {
                  navigate(`/blogs`);
                }
              }
            }}
          >
            <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-5">
              <Input.Defualt
                inputProps={{
                  name: "title",
                  placeholder: "متن را وارد نمایید",
                  defaultValue: item?.title,
                }}
                labelProps={{ children: "عنوان مقاله" }}
              />
              <Input.Defualt
                inputProps={{
                  name: "description",
                  placeholder: "متن را وارد نمایید",
                  defaultValue: item?.description,
                }}
                labelProps={{ children: "توضیحات مقاله" }}
              />

              <Input.Defualt
                inputProps={{
                  name: "tags[]",
                  placeholder: "تگ ها را وارد کنید",
                  defaultValue: item?.tags,
                }}
                labelProps={{ children: "تگ ها را وارد " }}
              />

              <Input.Select
                options={[
                  {
                    label: "غیر فعال",
                    value: 0,
                  },
                  {
                    label: "فعال",
                    value: 1,
                  },
                ]}
                name="is_visible"
                placeHolder="لطفا یک مورد را انتخاب کنید"
                labelProps={{
                  children: "وضعیت",
                }}
                defaultValue={item ? item?.is_visible : 1}
              />

              <Input.CustomInputFile
                labelProps={{ children: "انتخاب فایل رسانه" }}
                inputProps={{ name: "image" }}
                type={``}
                setFile={setFile}
              />
            </div>
            {file != null && (
              <div className="w-full flex items-center justify-end mt-2">
                <span
                  className="px-2 py-2 bg-primary rounded-lg text-white cursor-pointer ml-3"
                  onClick={() => {
                    navigator.clipboard.writeText(file);
                    toast.success("متن کپی شد");
                  }}
                >
                  کپی
                </span>
                <span>{file != null && file}</span>
              </div>
            )}

            <div
              className="my-5"
              style={{
                WebkitUserSelect: "unset",
                userSelect: "unset",
              }}
            >
              <SunEditor
                setContents={item?.content}
                setDefaultStyle="min-height:250px; font-family: vazir; font-size: 15px;"
                placeholder="متن مقاله"
                setOptions={{
                  stickyToolbar: "true",
                  resizingBar: false,
                  buttonList: [
                    ["formatBlock"], // 📌 اضافه کردن فرمت‌های پاراگراف و عنوان‌ها
                    ["bold", "underline", "italic"],
                    ["fontColor", "align", "list"],
                    ["link", "image", "video", "audio"],
                    ["undo", "redo"],
                    ["fullScreen"],
                  ],
                }}
                onChange={(value: string) => {
                  const textMusic: any =
                    window.document.getElementById("text-music");
                  if (textMusic) {
                    textMusic.value = value;
                  }
                }}
              />
              <input
                type="hidden"
                name="content"
                id="text-music"
                defaultValue={item?.content}
              />
            </div>

            <div className="my-5">
              <Input.Textarea
                inputProps={{
                  name: "seo[title]",
                  placeholder: "متن را وارد نمایید",
                  defaultValue: item?.seo?.title,
                }}
                labelProps={{ children: "توضیحات سئو " }}
              />
            </div>

            <div className="my-5">
              <Input.Preview
                defaultValue={item?.image}
                labelProps={{ children: "تصویر مقاله" }}
                inputProps={{ name: "image" }}
              />
            </div>
          </Form>
        </div>
      )}
    </Fragment>
  );
};

export default Create;
