import { Fragment, useEffect, useState } from "react";
import {
  AsyncDelete,
  CardHeader,
  Paginate,
  SearchBox,
  Table,
} from "@components/common";
import { Link, useLocation } from "react-router-dom";
import Icons from "@assets/icons";
import { API } from "@hooks";
import { queryGenerate } from "@components/utils";

const Blog = () => {
  const { list, loading, paginate, index, destroy } = API.useApi();

  const { search } = useLocation();
  let type = new URLSearchParams(search).get("type");
  const [query, setQuery] = useState<string>("?type=music");
  const [text, setText] = useState<string>("");

  useEffect(() => {
    let new_week = new URLSearchParams(search).get("new_week");
    let newQuery = "";
    if (type) {
      newQuery += "?type=" + type;
    } else {
      newQuery += "?type=music";
    }
    if (new_week) {
      newQuery += "?new_week=1";
    }

    if (text.length) {
      newQuery += "?text=" + text;
    }
    setQuery(newQuery);
  }, [type, search, text]);

  let changeablepaginate = new URLSearchParams(search).get("paginate");

  useEffect(() => {
    let querypaginate = new URLSearchParams(search).get("paginate");

    const timeOutId = setTimeout(
      () =>
        index(
          queryGenerate(
            querypaginate ? querypaginate + query : "/manager/blogs"
          )
        ),
      100
    );
    return () => clearTimeout(timeOutId);
  }, [query, changeablepaginate]);

  return (
    <Fragment>
      <CardHeader
        label={"لیست مقاله ها"}
        textButton={"ایجاد مقاله جدید"}
        toButton={"add"}
      />
      <div className="my-10">
        <div className="mb-5 px-2 grid lg:grid-cols-4">
          <SearchBox
            onChange={(value: string) => {
              setText(value);
            }}
          />
        </div>
        <Table
          loading={loading}
          head={[
            {
              label: "تصویر",
            },
            {
              label: "عنوان مقاله",
            },
            {
              label: "slug",
            },

            {
              label: "وضعیت",
            },
            {
              label: "مدیریت",
            },
          ]}
          rows={list || []}
          renderItem={(blog: any, i: number) => {
            return (
              <tr className="text-center" key={i}>
                <td>
                  <div className="flex justify-center">
                    <img
                      className="w-[50px] h-[50px] object-cover rounded-lg"
                      src={blog?.image || require("@images/no-photo.png")}
                      alt={blog.image}
                    />
                  </div>
                </td>
                <td>{blog?.title}</td>
                <td>{blog?.slug}</td>
                <td>
                  {blog?.is_visible == 1
                    ? "نمایش در وبسایت"
                    : "عدم نمایش در وبسایت"}
                </td>
                <td>
                  <div className="flex justify-center items-center gap-x-5">
                    <AsyncDelete
                      onDestroy={async () =>
                        await destroy("/manager/blogs", blog.id)
                      }
                    />
                    {changeablepaginate ? (
                      <Link
                        to={
                          type
                            ? "/blogs/edit/" + blog.id
                            : "/blogs/edit/" +
                              blog.id +
                              "?paginate=" +
                              changeablepaginate
                        }
                      >
                        <Icons.Edit />
                      </Link>
                    ) : (
                      <Link
                        to={
                          type
                            ? "/blogs/edit/" + blog.id + "?type=blogs"
                            : "/blogs/edit/" + blog.id
                        }
                      >
                        <Icons.Edit />
                      </Link>
                    )}
                  </div>
                </td>
              </tr>
            );
          }}
        />
      </div>
      <Paginate
        {...paginate}
        onChange={(link: string) => {
          index(queryGenerate(link + query));
        }}
      />
    </Fragment>
  );
};

export default Blog;
