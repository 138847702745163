import { Menu } from "@components/layout";
import { Route, Routes } from "react-router";
import Blog from "./blog";
import Create from "./create";

const BlogsPages = () => {
  return (
    <Menu
      menus={[
        {
          label: "لیست مقالات",
          to: "/blogs",
          type: "list",
        },
        {
          label: "ایجاد مقاله جدید",
          to: "/blogs/add",
        },
      ]}
    >
      <Routes>
        <Route path="/" element={<Blog />} />
        <Route path="/add" element={<Create />} />
        <Route path="/edit/:id" element={<Create />} />
        {/* <Route path='/edit/:id' element={<Creation />} /> */}
      </Routes>
    </Menu>
  );
};

export default BlogsPages;
